import { useEffect } from 'react';

export default function useKeyPress(targetKey, onPressDown = () => {}, onPressUp = () => {}) {

  useEffect(() => {
    // If pressed key is our target key then call down handler
    function downHandler({ key }) {
      if (key === targetKey && onPressDown) {
        onPressDown();
      }
    }

    // If released key is our target key then call up handler
    const upHandler = ({ key }) => {
      if (key === targetKey && onPressUp) {
        onPressUp();
      }
    };

    // Add event listeners
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });
}

// Sample Usage:
// const escapePressed = useKeyPress('Escape', onEscapePressed, onEscapeReleased);
