import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { useRouteMatch } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import useKeyPress from '../../hooks/use-key-press';
import * as ROUTES from '../../constants/routes';
import './Navigation.scss';

const Navigation = (props) => {
  const { history, poemId, tocData } = props;

  const [previousPoem, setPreviousPoem] = useState({});
  const [nextPoem, setNextPoem] = useState({});

  useKeyPress('ArrowLeft', goToPreviousPoem, null);
  useKeyPress('ArrowRight', goToNextPoem, null);

  const swipeHandlers = useSwipeable({ onSwiped: (evt) => onPageSwiped });

  const isPoemPage = !!useRouteMatch('/poems/');

  useEffect(() => {
    const currentIndex = tocData.findIndex((tocItem) => {
      return tocItem.poemId === poemId;
    });

    if (currentIndex > 0) {
      setPreviousPoem(tocData[currentIndex - 1]);
    } else {
      setPreviousPoem({});
    }

    if (currentIndex < tocData.length - 1) {
      setNextPoem(tocData[currentIndex + 1]);
    } else {
      setNextPoem({});
    }
  }, [poemId, tocData]);

  function onPageSwiped(evt) {
    console.log(evt);
    debugger;
  }

  function goToPreviousPoem() {
    if (previousPoem.poemId) {
      history.push(`/poems/${previousPoem.poemId}`);
    }
  }

  function goToNextPoem() {
    if (nextPoem.poemId) {
      history.push(`/poems/${nextPoem.poemId}`);
    }
  }

  return (
    <nav {...swipeHandlers}>
      <ul className="main-navigation">
        <li>
          <Link to={ROUTES.LANDING}>Flowers of Bad</Link>
        </li>
        <li>
          <Link to={ROUTES.TOC}>
            <span className="desktop">Table of </span>
            Contents
          </Link>
        </li>
        <li>
          <Link to={ROUTES.ABOUT}>About</Link>
        </li>
        {/*{process.env.NODE_ENV !== 'production' && (*/}
        {/*  <li>*/}
        {/*    <Link to={ROUTES.POEMFORM}>Form</Link>*/}
        {/*  </li>*/}
        {/*)}*/}
      </ul>
      {isPoemPage && (
        <ul className="next-previous-navigation">
          <li>
            {previousPoem.poemId && (
              <Link
                to={`/poems/${previousPoem.poemId}`}
                className="previous-poem"
              >
                &#8249; previous
              </Link>
            )}
          </li>
          <li>
            {nextPoem.poemId && (
              <Link to={`/poems/${nextPoem.poemId}`} className="next-poem">
                next &#8250;
              </Link>
            )}
          </li>
        </ul>
      )}
      ;
    </nav>
  );
};

export default withRouter(Navigation);

Navigation.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  poemId: PropTypes.string.isRequired,
  tocData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Navigation.defaultProps = {};
/* */
